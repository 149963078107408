import * as React from 'react'
import * as classnames from 'classnames'
import { Checkbox } from '@mui/material'
import { Box } from 'app/frontend/components/material/box'
import { Heading } from 'app/frontend/components/material/heading'
import * as styles from './table-of-contents.css'

type Props = {
  topic: GQL.TopicTaxonFields.Fragment
  toggleTopic?: (topicId: string, loIds: string[], checked: boolean) => void
  checked?: boolean
  disabled?: boolean
  children: JSX.Element | JSX.Element[]
  haveSections?: boolean
}

export class TableOfContentsTopic extends React.PureComponent<Props, {}> {
  toggleCheckbox = checked => {
    const { toggleTopic, topic } = this.props

    const loIds = topic.learningObjectives.map(lo => lo.id)
    toggleTopic(topic.id, loIds, checked)
  }

  render() {
    const { topic, toggleTopic, disabled, children, checked, haveSections } = this.props
    const preventSelect = disabled && !checked

    return (
      <Box alignSelf="stretch">
        <Box
          direction="row"
          alignItems="center"
          pad={{ horizontal: 'medium' }}
          data-test={`toc-topic-${topic.id}`}
        >
          {toggleTopic && (
            <Checkbox
              className={styles.checkbox}
              checked={checked}
              disabled={preventSelect}
              onChange={e => this.toggleCheckbox(e.target.checked)}
              data-test="toggle-topic-checkbox"
              inputProps={{
                'aria-labelledby': `${topic.id}GroupLabel`,
              }}
            />
          )}
          <Heading
            tag={haveSections ? 'h4' : 'h3'}
            size="h5"
            margin={{ left: 'none', top: 'medium', bottom: 'medium', right: 'medium' }}
            weight="semibold"
            data-test="topic-name"
            className={classnames({ [styles.disabled]: preventSelect })}
            id={`${topic.id}GroupLabel`}
          >
            {topic.name}
          </Heading>
        </Box>

        <Box
          alignSelf="stretch"
          data-test="toc-topic-children"
          role="group"
          aria-labelledby={`${topic.id}GroupLabel`}
        >
          {children}
        </Box>
      </Box>
    )
  }
}
