import * as React from 'react'
import * as classnames from 'classnames'
import { tns } from 'app/frontend/helpers/translations/i18n'
import { isEmpty } from 'lodash'
import {
  TextInputMaterial,
  ITextInputProps,
} from 'app/frontend/components/material/textinput/textinput'
import { Icon } from 'app/frontend/components/material/icon'
import { Box } from 'app/frontend/components/material/box'
import { Paragraph } from 'app/frontend/components/material/paragraph'

const t = tns('comps:search_input')
import * as styles from './search-input.css'

type OwnProps = {
  onSearch: (searchQuery: string) => void
  label?: string
}

type Props = OwnProps & ITextInputProps

type State = {
  searchQuery: string
  pristine: boolean
  searchForBoxFocus: boolean
}

export class SearchInput extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      searchQuery: '',
      pristine: true,
      searchForBoxFocus: false,
    }
  }

  keyDown = (e: any) => {
    // if enter, search
    if (e.keyCode === 13) {
      // don't submit the form
      e.preventDefault()

      this.search()
    }

    // arrow up
    else if (e.keyCode === 38) {
      // don't move the cursor
      e.preventDefault()

      this.setState({ searchForBoxFocus: false })
    }

    // arrow down
    else if (e.keyCode === 40) {
      // don't move the cursor
      e.preventDefault()

      this.setState({ searchForBoxFocus: true })
    }
  }

  search = () => {
    const { onSearch } = this.props
    const { pristine, searchQuery } = this.state

    if (!pristine) {
      this.setState({
        pristine: true,
        searchForBoxFocus: false,
      })

      onSearch(searchQuery)
    }
  }

  clearSearch = () => {
    const { onSearch } = this.props

    this.setState({
      pristine: true,
      searchForBoxFocus: false,
      searchQuery: '',
    })

    onSearch('')
  }

  updateQuery = value => {
    if (value.trim() === '') {
      // If input is empty, call clearSearch
      this.clearSearch()
    } else {
      this.setState({ searchQuery: value, pristine: false })
    }
  }

  render() {
    const { label } = this.props
    const { searchQuery, pristine, searchForBoxFocus } = this.state
    return (
      <Box alignSelf="stretch" alignItems="stretch">
        <Box
          alignSelf="stretch"
          alignItems="center"
          direction="row"
          className={styles.inputWrapper}
        >
          <TextInputMaterial
            icon={<Icon name="icon-search" size="large" className={styles.searchIcon} />}
            label={label || t('search')}
            multiline={false}
            name="search"
            onChange={this.updateQuery}
            type="text"
            value={searchQuery}
            onKeyDown={this.keyDown}
            data-test="search-input-field"
            className={styles.inputField}
          />
          {!isEmpty(searchQuery) && (
            <Icon
              name="icon-clear-circle"
              size="large"
              className={styles.clearSearchIcon}
              data-test="clear-search"
              onClick={this.clearSearch}
            />
          )}
        </Box>
        {!isEmpty(searchQuery) && !pristine && (
          <Box
            onClick={this.search}
            onKeyDown={this.keyDown}
            justify="between"
            alignItems="center"
            direction="row"
            alignSelf="stretch"
            pad="medium"
            className={classnames(
              { [styles.searchForBoxFocus]: searchForBoxFocus },
              styles.searchForBox
            )}
            data-test="search-for-box"
          >
            <Paragraph margin="none">{t('search_for', { searchQuery })}</Paragraph>
            <Paragraph margin="none" size="small" className={styles.pressEnter}>
              Press enter
            </Paragraph>
          </Box>
        )}
      </Box>
    )
  }
}
