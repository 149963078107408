import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ApolloQueryResult, useApolloClient } from '@apollo/client'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@mui/styles'
import { showModal } from 'app/frontend/components/material/modal/modal-actions'
import {
  CUSTOM_QUESTIONS_MODAL,
  CustomQuestionsView,
} from 'app/frontend/pages/material/teach/assessment-builder/custom-questions-modal'
import { isEnabled } from 'app/frontend/helpers/feature'
import { ParentEntityType } from 'app/typings/commons'
import {
  TEACH_COURSE_ASSESSMENT_ADD_CUSTOM,
  TEACH_SECTION_ASSESSMENT_ADD_CUSTOM,
} from 'app/frontend/data/mixpanel-events'
import { sendEventTeachAssessment } from 'app/frontend/helpers/mixpanel/teach'
import * as HAS_STUDENT_STARTED_ASSIGNMENT_QUERY from 'app/frontend/pages/material/teach/compositions/connected/has-student-started-assignment/has-student-started-assignment.gql'
import { tns } from 'app/frontend/helpers/translations/i18n'

const t = tns('teach:assessment_questions')

interface AddCustomQuestionButtonProps {
  assessment: GQL.GetAssignment.Assignment
}

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette?.grey?.[600],
    borderColor: theme.palette?.grey?.[100],
    letterSpacing: '0.0625rem',
    minWidth: '5.5rem',
    minHeight: '2.6em',
    padding: '0 1em',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette?.grey?.[50],
      borderColor: theme.palette?.grey?.[600],
    },
    '&:focus': {
      outlineColor: `${theme.palette?.blue?.[900]} !important`,
      '& > span:nth-child(2)::after': {
        content: '""',
        position: 'absolute',
        bottom: '0.125rem',
        left: '50%',
        width: '0',
        height: '0.125rem',
        backgroundColor: 'currentColor',
        transform: 'translateX(-50%)',
        animation: '$expandFromCenter 200ms linear forwards',
      },
    },
    '& > span:nth-child(2)': { position: 'relative', display: 'inline-block' },
  },
  '@keyframes expandFromCenter': { '0%': { width: '0' }, '100%': { width: '100%' } },
  span: {
    paddingTop: '0.0125rem',
    display: 'block',
    '&:focus': { borderBottom: '1px solid black' },
  },
}))

export const AddCustomQuestionButton: React.FunctionComponent<AddCustomQuestionButtonProps> = ({
  assessment,
}) => {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const [isDisabled, setDisabled] = useState(false)
  const isMountedRef = useRef(false)
  const styles = useStyles()

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  /**
   * On Add Custom Question handler
   *
   * this will validate the has student started assessment
   * state prior to show `CustomQuestionsModal`.
   */
  const onClickHandler = async (): Promise<void> => {
    const { id, sectionId } = assessment
    // determine the `parentEntity` type
    const parentEntityType = sectionId ? ParentEntityType.Section : ParentEntityType.Course
    // send mixpanel event
    sendEventTeachAssessment(
      parentEntityType === ParentEntityType.Course
        ? TEACH_COURSE_ASSESSMENT_ADD_CUSTOM
        : TEACH_SECTION_ASSESSMENT_ADD_CUSTOM,
      id
    )
    // disable the `add custom question` button
    setDisabled(true)

    try {
      // fetch the status of the student assessment started
      const { data } = (await client.query({
        query: HAS_STUDENT_STARTED_ASSIGNMENT_QUERY,
        variables: {
          assignmentId: id,
        },
        fetchPolicy: 'network-only',
        context: { silenceErrors: true },
      })) as ApolloQueryResult<GQL.HasStudentStartedAssignment.Query>

      // check students have started the assessment or not
      if (!data.hasStudentStartedAssignment) {
        dispatch(
          showModal(CUSTOM_QUESTIONS_MODAL, {
            view: isEnabled('customFreeResponseQuestions')
              ? CustomQuestionsView.Select
              : CustomQuestionsView.MultipleChoice,
            assessmentId: id,
          })
        )
      }
    } finally {
      if (isMountedRef.current) {
        // enable the `add custom question` button
        setDisabled(false)
      }
    }
  }

  return (
    <Button
      data-bi="add-custom-question-button"
      variant="outlined"
      startIcon={<AddIcon fontSize="small" />}
      aria-label={t('add_custom_question')}
      disabled={isDisabled}
      onClick={onClickHandler}
      className={styles.button}
      disableFocusRipple={true}
    >
      <span className={styles.span}>{t('add_custom_question')}</span>
    </Button>
  )
}

AddCustomQuestionButton.displayName = 'AddCustomQuestionButton'
