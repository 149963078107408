import * as React from 'react'
import {
  Box,
  Stack,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  styled,
} from '@mui/material'
import { tns } from 'app/frontend/helpers/translations/i18n'
import {
  QuestionViewPreferencesOptionsView,
  QuestionViewPreferencesOptionsViewDensity,
} from './helper'

const t = tns('teach:assessment_question_view_preferences')

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButton-root': {
    fontSize: '0.75rem',
    fontWeight: 600,
    cursor: 'pointer',
    padding: '0px 1.25rem',
    lineHeight: '1.5rem',
    textTransform: 'uppercase',
    border: '1px solid #e0e0e0',
    '&.Mui-selected': {
      backgroundColor: '#00838f',
      color: 'white',
      '&:hover': {
        backgroundColor: '#00838f',
      },
    },
    '&:not(:first-of-type)': {
      marginLeft: '-1px',
    },
  },
}))

const StyledButton = styled(Button)(() => ({
  fontWeight: 600,
  minWidth: '5.5rem',
  minHeight: '2.6em',
  display: 'inline-flex',
  letterSpacing: '0.0625rem',
  padding: '0px 1em',
  borderRadius: '0.25rem',
  textTransform: 'none',
  '&.MuiButton-outlined': {
    borderColor: '#e0e0e0',
    color: '#424242',
  },
  '&.MuiButton-contained': {
    backgroundColor: '#00838f',
    color: 'white',
    '&:hover': {
      backgroundColor: '#006064',
    },
  },
}))

export interface Props {
  onRequestClose?: () => void
  viewBy: QuestionViewPreferencesOptionsView
  displayDensity: QuestionViewPreferencesOptionsViewDensity
  updateQuestionViewPreferences: (
    viewBy: QuestionViewPreferencesOptionsView,
    density: QuestionViewPreferencesOptionsViewDensity
  ) => void
}

export const QuestionViewPreferencesContent: React.FunctionComponent<Props> = ({
  onRequestClose,
  viewBy,
  displayDensity,
  updateQuestionViewPreferences,
}) => {
  const [viewByState, setViewByState] = React.useState(viewBy)
  const [densityState, setDensityState] = React.useState(displayDensity)

  const contentRef = React.useRef(null)
  const objectiveButtonRef = React.useRef(null)
  const orderButtonRef = React.useRef(null)
  const expandedButtonRef = React.useRef(null)
  const collapsedButtonRef = React.useRef(null)
  const cancelButtonRef = React.useRef(null)
  const applyButtonRef = React.useRef(null)

  const getFocusableElements = React.useCallback(() => {
    if (!contentRef.current) {
      return []
    }
    return [
      objectiveButtonRef.current,
      orderButtonRef.current,
      expandedButtonRef.current,
      collapsedButtonRef.current,
      cancelButtonRef.current,
      applyButtonRef.current,
    ].filter(Boolean)
  }, [])

  const handleKeyDown = React.useCallback(
    e => {
      const focusableElements = getFocusableElements()
      if (!focusableElements.length) {
        return
      }

      if (e.key === 'Tab') {
        e.preventDefault()
        const currentIndex = focusableElements.indexOf(document.activeElement)
        const nextIndex = e.shiftKey
          ? currentIndex <= 0
            ? focusableElements.length - 1
            : currentIndex - 1
          : currentIndex >= focusableElements.length - 1
          ? 0
          : currentIndex + 1
        focusableElements[nextIndex]?.focus()
      }

      if (e.key === 'Escape') {
        onRequestClose?.()
      }
    },
    [onRequestClose, getFocusableElements]
  )

  React.useEffect(() => {
    getFocusableElements()[0]?.focus()
  }, [getFocusableElements])

  const handleOrderOption = (
    _: React.MouseEvent<HTMLElement>,
    value: QuestionViewPreferencesOptionsView
  ) => {
    if (value !== null) {
      setViewByState(value)
    }
  }

  const handleDensity = (
    _: React.MouseEvent<HTMLElement>,
    value: QuestionViewPreferencesOptionsViewDensity
  ) => {
    if (value !== null) {
      setDensityState(value)
    }
  }

  const handleApply = () => {
    updateQuestionViewPreferences(viewByState, densityState)
    onRequestClose?.()
  }

  return (
    <Box
      ref={contentRef}
      onKeyDown={handleKeyDown}
      role="dialog"
      aria-modal="true"
      tabIndex={-1}
      sx={{
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: '1.5rem',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
      }}
    >
      <Stack spacing={2.5}>
        <Box>
          <Typography
            variant="h5"
            component="h5"
            id="viewGroupLabel"
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              color: '#424242',
              mb: 1.5,
            }}
          >
            View
          </Typography>
          <StyledToggleButtonGroup
            value={viewByState}
            exclusive
            onChange={handleOrderOption}
            aria-labelledby="viewGroupLabel"
            sx={{ mb: 1 }}
          >
            <ToggleButton
              ref={objectiveButtonRef}
              value={QuestionViewPreferencesOptionsView.BY_OBJECTIVE}
            >
              {t('objective')}
            </ToggleButton>
            <ToggleButton ref={orderButtonRef} value={QuestionViewPreferencesOptionsView.BY_ORDER}>
              {t('order')}
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Typography
            variant="body2"
            sx={{
              color: '#484848',
              fontSize: '0.75rem',
            }}
            data-test="questions-view-by"
          >
            Questions will be organized by{' '}
            <span style={{ fontWeight: 700 }}>
              {viewByState === QuestionViewPreferencesOptionsView.BY_OBJECTIVE
                ? 'objective'
                : 'order'}
            </span>
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="h5"
            component="h5"
            id="displayGroupLabel"
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              color: '#424242',
              mb: 1.5,
            }}
          >
            Display Density
          </Typography>
          <StyledToggleButtonGroup
            value={densityState}
            exclusive
            onChange={handleDensity}
            aria-labelledby="displayGroupLabel"
            sx={{ mb: 1 }}
          >
            <ToggleButton
              ref={expandedButtonRef}
              value={QuestionViewPreferencesOptionsViewDensity.EXPANDED}
            >
              {t('expanded')}
            </ToggleButton>
            <ToggleButton
              ref={collapsedButtonRef}
              value={QuestionViewPreferencesOptionsViewDensity.COLLAPSED}
            >
              {t('collapsed')}
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Typography
            variant="body2"
            sx={{
              color: '#484848',
              fontSize: '0.75rem',
            }}
            data-test={`display-density-${densityState.toLowerCase()}`}
          >
            Questions will be{' '}
            <span style={{ fontWeight: 700 }}>
              {densityState === QuestionViewPreferencesOptionsViewDensity.EXPANDED
                ? 'expanded'
                : 'collapsed'}
            </span>{' '}
            to show all content
          </Typography>
        </Box>

        <Stack direction="row" spacing={1.5} justifyContent="flex-end" sx={{ mt: 2 }}>
          <StyledButton
            ref={cancelButtonRef}
            variant="outlined"
            onClick={onRequestClose}
            data-bi="question-order-preferences-cancel"
          >
            Cancel
          </StyledButton>
          <StyledButton
            ref={applyButtonRef}
            variant="contained"
            onClick={handleApply}
            data-bi="question-order-preferences-apply"
          >
            Apply
          </StyledButton>
        </Stack>
      </Stack>
    </Box>
  )
}

QuestionViewPreferencesContent.displayName = 'QuestionViewPreferencesContent'
